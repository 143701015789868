import React from "react"

import { Box, Typography, Grid, Button, Snackbar, SnackbarContent, IconButton, CardActionArea, Card, CardContent, CardMedia } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { StaticImage } from "gatsby-plugin-image"


// import Ultrasound from "./images/ultrasound";
// import Radiography from "./images/radiography";
// import Interventionalxray from "./images/interventional-xray";
// import Fluoroscopy from "./images/fluoroscopy";
// import Computedtomography from "./images/computed-tomography";
// import Mri from "./images/mri";
// import Radiationoncology from "./images/radiation-oncology";
// import Refurbishedsystems from "./images/refurbished-systems";
// import Molecularimaging from "./images/molecular-imaging";
// import Ecg from "./images/ecg";
// import Motherchildcare from "./images/mother-childcare";
// import Patientmonitoring from "./images/patient-monitoring";
// import Emergencycare from "./images/emergency-care";
// import Sleeprespiratory from "./images/sleep-respiratory";
// import Hospitalrespiratory from "./images/hospital-respiratory";
// import Customerservice from "./images/customer-service";
// import Therapies from "./images/therapies";
// import Clinicalinformatics from "./images/clinical-informatics";
// import Consumables from "./images/consumables";

import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import { Modal } from 'antd';


import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);





export default function Ourproducts(){

  const [visible, setVisible] = React.useState(false);
  const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [mobile, setMobile] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [result, setResult] = React.useState('');
    const [submitname, setSubmitname] = React.useState('Submit');

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = e => {
    setVisible(false);
  };

  const handleChange = (event) =>{
    const target = event.target;
    const value = target.value;
    const name = target.name;
    if(name==="name")
    {
      setName(value);
    }
    if(name==="email")
    {
      setEmail(value);
    }
    
    if(name==="message")
    {
      setMessage(value);
    }

    if(name==="mobile")
    {
      setMobile(value);
    }
    
  }
 

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
     setOpen(false);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitname('Submitting... ')
    let collection={};
      collection.name = name;
      collection.email = email;
      collection.message = message;
      collection.mobile = mobile;
      collection.scode = "sdferderv3876MBg";

      fetch('https://apiv2.chennaihost.com/site/contact', {
        method: "post",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },

        //make sure to serialize your JSON body
        body: JSON.stringify(collection),
        })
        .then( (response) => { 
          return response.json();
        })
        .then((data) => {
          if(data)
          {
            setResult(data.result);
            setName('');
            setEmail('');
            setMessage('');
            setMobile('');
            setOpen(true);
            setSubmitname('submit');
         
          }
       }
        )
   


  };


  return(

  
    <ThemeProvider theme={theme}>

        <Grid container justify="center">
        <Grid item xs={12} sm={4} md={3}>
          <Box p={1}>
        <Card onClick={showModal}>
        <CardActionArea>
        <CardMedia
          title="Contemplative Reptile"
        />
         <StaticImage
      src="../images/products/ultrasound.jpg"
      alt="Ultrasound"
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
    />
        <CardContent>
          <Typography gutterBottom variant="body1" align='center'>
         <b>Ultrasound</b> 
          </Typography>
 
        </CardContent>

       </CardActionArea>
    </Card>
    </Box>
        </Grid>


        <Grid item xs={12} sm={4} md={3}>
        <Box p={1}>
        <Card onClick={showModal}>
        <CardActionArea>
        <CardMedia
          title="Contemplative Reptile"
        />
        <StaticImage
      src="../images/products/radiography.jpg"
      alt="Radiography"
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
    />
        <CardContent>
        <Typography gutterBottom variant="body1" align='center' style={{ color:'#0e359d' }}>
         <b>Radiography</b> 
          </Typography>
        </CardContent>
        </CardActionArea>
    </Card>
    </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
        <Box p={1}>
        <Card onClick={showModal}>
        <CardActionArea>
        <CardMedia
          title="Contemplative Reptile"
        />
        <StaticImage
      src="../images/products/interventional-xray.jpg"
      alt="Interventional X-ray"
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
    />
        <CardContent>
        <Typography gutterBottom variant="body1" align='center' style={{ color:'#0e359d' }}>
         <b>Interventional X-ray</b> 
          </Typography>
        </CardContent>
        </CardActionArea>
    </Card>
    </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
        <Box p={1}>
        <Card onClick={showModal}>
        <CardActionArea>
        <CardMedia
          title="Contemplative Reptile"
        />
        <StaticImage
      src="../images/products/fluoroscopy.jpg"
      alt="Fluoroscopy"
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
    />
        <CardContent>
        <Typography gutterBottom variant="body1" align='center' style={{ color:'#0e359d' }}>
         <b>Fluoroscopy</b> 
          </Typography>
        </CardContent>
        </CardActionArea>
    </Card>
    </Box>
        </Grid>


        <Grid item xs={12} sm={4} md={3}>
        <Box p={1}>
        <Card onClick={showModal}>
        <CardActionArea>
        <CardMedia
          title="Contemplative Reptile"
        />
        <StaticImage
      src="../images/products/computed-tomography.jpg"
      alt="Computed Tomography"
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
    />
        <CardContent>
        <Typography gutterBottom variant="body1" align='center' style={{ color:'#0e359d' }}>
         <b>Computed Tomography</b> 
          </Typography>
        </CardContent>
        </CardActionArea>
    </Card>
    </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
        <Box p={1}>
        <Card onClick={showModal}>
        <CardActionArea>
        <CardMedia
          title="Contemplative Reptile"
        />
        <StaticImage
      src="../images/products/mri.jpg"
      alt="MRI systems & solutions"
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
    />
        <CardContent>
        <Typography gutterBottom variant="body1" align='center' style={{ color:'#0e359d' }}>
         <b> MRI systems & solutions</b> 
          </Typography>
        </CardContent>
        </CardActionArea>
    </Card>
    </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
        <Box p={1}>
        <Card onClick={showModal}>
        <CardActionArea>
        <CardMedia
          title="Contemplative Reptile"
        />
        <StaticImage
      src="../images/products/radiation-oncology.jpg"
      alt=" Radiation Oncology"
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
    />
        <CardContent>
        <Typography gutterBottom variant="body1" align='center' style={{ color:'#0e359d' }}>
         <b> Radiation Oncology</b> 
          </Typography>
        </CardContent>
        </CardActionArea>
    </Card>
    </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
        <Box p={1}>
        <Card onClick={showModal}>
        <CardActionArea>
        <CardMedia
          title="Contemplative Reptile"
        />
        <StaticImage
      src="../images/products/refurbished-systems.jpg"
      alt="Refurbished Systems"
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
    />
        <CardContent>
        <Typography gutterBottom variant="body1" align='center' style={{ color:'#0e359d' }}>
         <b>Refurbished Systems</b> 
          </Typography>
        </CardContent>
        </CardActionArea>
    </Card>
    </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
        <Box p={1}>
        <Card onClick={showModal}>
        <CardActionArea>
        <CardMedia
          title="Contemplative Reptile"
        />
        <StaticImage
      src="../images/products/molecular-imaging.jpg"
      alt="Advanced Molecular Imaging"
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
    />
        <CardContent>
        <Typography gutterBottom variant="body1" align='center' style={{ color:'#0e359d' }}>
         <b>Advanced Molecular Imaging</b> 
          </Typography>
        </CardContent>
        </CardActionArea>
    </Card>
    </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
        <Box p={1}>
        <Card onClick={showModal}>
        <CardActionArea>
        <CardMedia
          title="Contemplative Reptile"
        />
        <StaticImage
      src="../images/products/ecg.jpg"
      alt="Diagnostic ECG"
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
    />
        <CardContent>
        <Typography gutterBottom variant="body1" align='center' style={{ color:'#0e359d' }}>
         <b>Diagnostic ECG</b> 
          </Typography>
        </CardContent>
        </CardActionArea>
    </Card>
    </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
        <Box p={1}>
        <Card onClick={showModal}>
        <CardActionArea>
        <CardMedia
          title="Contemplative Reptile"
        />
        <StaticImage
      src="../images/products/mother-childcare.jpg"
      alt="Mother & Child Care"
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
    />
        <CardContent>
        <Typography gutterBottom variant="body1" align='center' style={{ color:'#0e359d' }}>
         <b>Mother & Child Care</b> 
          </Typography>
        </CardContent>
        </CardActionArea>
    </Card>
    </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
        <Box p={1}>
        <Card onClick={showModal}>
        <CardActionArea>
        <CardMedia
          title="Contemplative Reptile"
        />
        <StaticImage
      src="../images/products/patient-monitoring.jpg"
      alt="Patient Monitoring"
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
    />
        <CardContent>
        <Typography gutterBottom variant="body1" align='center' style={{ color:'#0e359d' }}>
         <b>Patient Monitoring</b> 
          </Typography>
        </CardContent>
        </CardActionArea>
    </Card>
    </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
        <Box p={1}>
        <Card onClick={showModal}>
        <CardActionArea>
        <CardMedia
          title="Contemplative Reptile"
        />
        <StaticImage
      src="../images/products/emergency-care.jpg"
      alt="Emergency Care & Resuscitation"
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
    />
        <CardContent>
        <Typography gutterBottom variant="body1" align='center' style={{ color:'#0e359d' }}>
         <b>Emergency Care & Resuscitation</b> 
          </Typography>
        </CardContent>
        </CardActionArea>
    </Card>
    </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
        <Box p={1}>
        <Card onClick={showModal}>
        <CardActionArea>
        <CardMedia
          title="Contemplative Reptile"
        />
        <StaticImage
      src="../images/products/sleep-respiratory.jpg"
      alt="Sleep and Respiratory Care"
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
    />
        <CardContent>
        <Typography gutterBottom variant="body1" align='center' style={{ color:'#0e359d' }}>
         <b>Sleep and Respiratory Care</b> 
          </Typography>
        </CardContent>
        </CardActionArea>
    </Card>
    </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
        <Box p={1}>
        <Card onClick={showModal}>
        <CardActionArea>
        <CardMedia
          title="Contemplative Reptile"
        />
        <StaticImage
      src="../images/products/hospital-respiratory.jpg"
      alt="Hospital Respiratory Care"
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
    />
        <CardContent>
        <Typography gutterBottom variant="body1" align='center' style={{ color:'#0e359d' }}>
         <b>Hospital Respiratory Care</b> 
          </Typography>
        </CardContent>
        </CardActionArea>
    </Card>
    </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
        <Box p={1}>
        <Card onClick={showModal}>
        <CardActionArea>
        <CardMedia
          title="Contemplative Reptile"
        />
        <StaticImage
      src="../images/products/customer-service.jpg"
      alt="Customer Service Solutions"
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
    />
        <CardContent>
        <Typography gutterBottom variant="body1" align='center' style={{ color:'#0e359d' }}>
         <b>Customer Service Solutions</b> 
          </Typography>
        </CardContent>
        </CardActionArea>
    </Card>
    </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
        <Box p={1}>
        <Card onClick={showModal}>
        <CardActionArea>
        <CardMedia
          title="Contemplative Reptile"
        />
        <StaticImage
      src="../images/products/therapies.jpg"
      alt="Interventional Devices & Therapies"
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
    />
        <CardContent>
        <Typography gutterBottom variant="body1" align='center' style={{ color:'#0e359d' }}>
         <b> Interventional Devices & Therapies</b> 
          </Typography>
        </CardContent>
        </CardActionArea>
    </Card>
    </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
        <Box p={1}>
        <Card onClick={showModal}>
        <CardActionArea>
        <CardMedia
          title="Contemplative Reptile"
        />
        <StaticImage
      src="../images/products/clinical-informatics.jpg"
      alt="Clinical Informatics"
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
    />
        <CardContent>
        <Typography gutterBottom variant="body1" align='center' style={{ color:'#0e359d' }}>
         <b> Clinical Informatics</b> 
          </Typography>
        </CardContent>
        </CardActionArea>
    </Card>
    </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
        <Box p={1}>
        <Card onClick={showModal}>
        <CardActionArea>
        <CardMedia
          title="Contemplative Reptile"
        />
        <StaticImage
      src="../images/products/consumables.jpg"
      alt="Consumables and accessories"
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
    />
        <CardContent>
        <Typography gutterBottom variant="body1" align='center' style={{ color:'#0e359d' }}>
         <b>Consumables and accessories</b> 
          </Typography>
        </CardContent>
        </CardActionArea>
    </Card>
    </Box>
        </Grid>


      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
          
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}>
          <SnackbarContent
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" style={{display: 'flex', alignItems: 'center',}}>
           {result}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
          <CloseIcon style={{fontSize: 20,}} />
        </IconButton>,
      ]}
     
    />
        </Snackbar>

      {/* modal starts */}
  
  <Modal
          title="Contact Us"
          visible={visible}
          onCancel={handleCancel}
          footer={[]}
        >
              <ValidatorForm onSubmit={handleSubmit}  autoComplete="off">
<TextValidator 
          id="name"
          name="name"
          label="Your Name" 
          value={name}
          onChange={handleChange}
          validators={['required']}
          errorMessages={['this field is required']}
          fullWidth
          margin="normal"
          variant="outlined" />

<TextValidator 
          id="mobile"
          name="mobile"
          label="Mobile" 
          value={mobile}
          onChange={handleChange}
          validators={['required']}
          errorMessages={['this field is required']}
          fullWidth
          margin="normal"
          variant="outlined" />

          <TextValidator 
          id="email"
          name="email"
          label="Email" 
          value={email}
          onChange={handleChange}
          validators={['required', 'isEmail']}
          errorMessages={['this field is required', 'email is not valid']}
          fullWidth
          margin="normal"
          variant="outlined" />

          <TextValidator 
          id="message"
          name="message"
          label="Message" 
          value={message}
          onChange={handleChange}
          multiline
          rows="4"
          validators={['required']}
          errorMessages={['this field is required']}
          fullWidth
          margin="normal"
          variant="outlined" />
          
           

        <Button variant="contained" color='primary' size="large" type="submit">{submitname}</Button>
         </ValidatorForm>
  
        </Modal>

    </ThemeProvider>

);
  }
